import React from "react";
import { ColumnsType } from "antd/lib/table";
import css from "./styles.module.css";

interface Props {
  style?: React.CSSProperties;
}
export default function Skeleton(props: Props) {
  return (
    <span style={props.style} className={css.skeleton}>
      &zwnj;
    </span>
  );
}

interface GhostLoaderProps {
  children?: React.ReactNode;
  loading?: boolean;
  width?: number | string;
}

/**
 * Renders a Skeleton placeholder when `loading` is true.  Otherwise, renders children
 */
export function SkeletonLoader({ children, loading, width }: GhostLoaderProps) {
  if (!loading) {
    return <>{children}</>;
  }
  return <Skeleton style={{ width }} />;
}

//Helper functions for tables

export class TableHelper {
  /**
   * Create a fake array of a specified length with a key field of its index
   * @param length Length of fake array
   * @param key
   * @returns [
   *  {
   *    key: 0,
   *  },
   *  {
   *    key: 1,
   *  },
   * ]
   */
  static genFakeArray(length: number, key = "key") {
    const fakeArray: any[] = [];
    for (let i = 0; i < length; i++) {
      fakeArray.push({ [key]: i });
    }
    return fakeArray;
  }

  /**
   * Filter the `render` field from RC-Table's Columns so to not cause any errors while ghost loading
   * @param columns Table columns
   */
  static filterColumnRender(columns: ColumnsType) {
    const newColumns = [...columns];
    newColumns.forEach((value) => {
      delete value.render;
    });
    return newColumns;
  }

  static SkeletonRow = (loading: boolean) => (tableProps: any) => {
    if (loading) {
      return (
        <tr className="ant-table-row ant-table-row-level-0">
          <td colSpan={100}>
            <Skeleton />
          </td>
        </tr>
      );
    }
    return (
      <tr pendo-id="tableRow" {...tableProps}>
        {tableProps.children}
      </tr>
    );
  };
}
