import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import Button from "antd/lib/button";
import NavigationHeader from "../components/header";
import CompanyTable from "../components/company/table/CompanyTable";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { createCompany, createCompanyVariables } from "__generated__/createCompany";
import { InternalFeature } from "components/InternalFeature/InternalFeature";

const MUTATION_CREATE_COMPANY = gql`
  mutation createCompany($fields: InputCompanyFields!) {
    createCompany(fields: $fields) {
      id
      name
    }
  }
`;

export default function Companies() {
  const [createCompanyMutation] = useMutation<createCompany, createCompanyVariables>(MUTATION_CREATE_COMPANY);

  const createCompanyPrompt = () => {
    const name = window.prompt("Name of company?");
    if (!name) {
      alert("Invalid name");
      return;
    }
    return createCompanyMutation({
      variables: {
        fields: {
          name: name,
        },
      },
    });
  };

  return (
    <Layout style={{ padding: "8px 24px" }}>
      <NavigationHeader />
      <Layout.Content style={{ padding: "16px 0" }}>
        <Row align="middle" justify="space-between">
          <Col>
            <h1 className="pageTitle">Companies</h1>
          </Col>
          <Col>
            <InternalFeature>
              <Button type="primary" data-testid="btnCreateCompany" pendo-id="createCompanyButton" onClick={createCompanyPrompt}>
                Create company (internal)
              </Button>
            </InternalFeature>
          </Col>
        </Row>
        <CompanyTable />
      </Layout.Content>
    </Layout>
  );
}
