import React from "react";
import { Avatar as AntdAvatar, Tooltip } from "antd";
import styled from "styled-components";

const StyledAntdAvatar = styled(AntdAvatar)`
  background-color: #ccc;
`;
const StyledB = styled.b<{ fontSize?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "inherit")};
`;

interface Props {
  firstName: NullableAndOptional<string>;
  fontSize?: string;
  lastName: NullableAndOptional<string>;
  showToolTip?: boolean;
  size?: number; // default: 23
}

export default function Avatar({ firstName, fontSize, lastName, showToolTip, size }: Props) {
  let fullName = "";
  if (firstName) {
    fullName += firstName + " ";
  }
  if (lastName) {
    fullName += lastName;
  }
  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : "";
  return (
    <Tooltip title={showToolTip && fullName}>
      <StyledAntdAvatar size={size || 23}>
        <StyledB fontSize={fontSize}>{initials}</StyledB>
      </StyledAntdAvatar>
    </Tooltip>
  );
}
