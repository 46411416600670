import Table, { ColumnProps } from "antd/lib/table";
import React from "react";
import Link from "next/link";
import { gql } from "@apollo/client";
import css from "./styles.module.scss";
import Skeleton, { TableHelper } from "components/Skeleton";
import { getCompanyList, getCompanyList_currentUser_companies } from "__generated__/getCompanyList";
import useQuery from "hooks/useQuery";
import { camelCaseToCamelCaseWithSpaces } from "helpers/utils";

export const COMPANY_LIST_QUERY_GQL = gql`
  query getCompanyList {
    currentUser {
      id
      companies {
        id
        address
        city
        documentsCount
        id
        name
        state
        staff {
          id
          userId
          roleAtCompany
        }
        type
      }
    }
  }
`;

const getCompanyColumns = (currentUserID: string): ColumnProps<getCompanyList_currentUser_companies>[] => [
  {
    title: "Company Name",
    dataIndex: "name",
    key: "name",
    render: (text, company) => (
      <>
        <span className={css.company_initials}>{getCompanyInitials(company?.name || "")}</span>
        <Link href={`/company/${company.id}/projects`} passHref>
          <a pendo-id="companyLink">{company.name}</a>
        </Link>
      </>
    ),
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (text, { zipCode, address, city, state }: any) => (
      <>
        <div>{address}</div>
        <div>
          {city}
          {", "}
          {state} {zipCode}
        </div>
      </>
    ),
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    render: (text, company) => <span>{company.documentsCount} Documents&nbsp;</span>,
  },
  {
    title: "Company Permission",
    dataIndex: "permission",
    key: "permission",
    render: (text, company) => {
      if (!company?.staff) return "";
      const currentMember = company.staff.find((staffMember) => staffMember.userId === currentUserID);
      return currentMember?.roleAtCompany || "";
    },
  },
  {
    title: "Company Type",
    dataIndex: "type",
    key: "type",
    render: (text, company) => camelCaseToCamelCaseWithSpaces(company?.type || ""),
  },
];

export default function CompanyTable() {
  const { loading, error, data } = useQuery<getCompanyList>(COMPANY_LIST_QUERY_GQL);
  if (error) return <p>Error: ${error.message}</p>;
  const currentUser = data?.currentUser;
  const companies = currentUser?.companies;
  const companyColumns = getCompanyColumns(currentUser?.id || "");
  const tableOrLoading = (
    <Table
      data-testid="companyTable"
      components={{ body: { row: TableHelper.SkeletonRow(loading) } }}
      columns={companyColumns}
      dataSource={companies || TableHelper.genFakeArray(10, "id")}
      pagination={false}
      rowKey="id"
    />
  );
  return (
    <div className={css.company_table} pendo-id="companyTable">
      <div style={{ marginBottom: "20px" }}>
        <div data-testid="companiesTotal" className={css.table__title}>
          <strong>Companies: {loading ? <Skeleton style={{ width: 20 }} /> : companies?.length}</strong>
        </div>
      </div>
      {tableOrLoading}
    </div>
  );
}

const getCompanyInitials = (name: string) => {
  const names = name.split(" ");
  let initials = names[0].substring(0, 2).toUpperCase();

  if (names.length > 1) {
    initials = names[0].substring(0, 1).toUpperCase() + names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};
