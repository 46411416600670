import React from "react";
import Layout from "antd/lib/layout";
import css from "./header.module.scss";
import UserUtility from "./UserUtility";

export default function NavigationHeader() {
  return (
    <Layout.Header className={css.topNavigationHeader}>
      <div className={css.header}>
        <UserUtility />
      </div>
    </Layout.Header>
  );
}
